Alpine.store('transfert', {

    products: [],
    blink: null,
    register_at: null,
    wid: null,
    from_store_id: null,
    from_warehouse_id: null,
    to_store_id: null,
    to_warehouse_id: null,

    reset() {
        this.products = [];
        this.blink = null;
        this.register_at = null;
        this.from_store_id = null;
        this.from_warehouse_id = null;
        this.to_store_id = null;
        this.to_warehouse_id = null;
        this.storage?.persist();
        this.received = undefined;
    },

    formData() {

        return {
            products: this.products,
            from_store_id: this.from_store_id,
            from_warehouse_id: this.from_warehouse_id,
            to_store_id: this.to_store_id,
            to_warehouse_id: this.to_warehouse_id,
            register_at: this.register_at
        };
    },

    isEmpty() {
        return !!(this.products?.length)
    },

    get storage() {
        if (!localStorage) {
            return null;
        }

        let store = this;
        return {
            persist() {
                const data = {
                    products: store.products,
                    from_store_id: store.from_store_id,
                    from_warehouse_id: store.from_warehouse_id,
                    to_store_id: store.to_store_id,
                    to_warehouse_id: store.to_warehouse_id,
                    register_at: store.register_at,
                    wid: store.wid
                }
                localStorage.setItem('transfert_data', JSON.stringify(data))
            },
            data() {
                return JSON.parse(localStorage.getItem('transfert_data') || '{}');
            },
            restore(data) {
                if (!data) data = this.data();
                store.products = data.products || [];
                store.from_store_id = data.from_store_id;
                store.from_warehouse_id = data.from_warehouse_id;
                store.to_store_id = data.to_store_id;
                store.to_warehouse_id = data.to_warehouse_id;
                store.register_at = data.register_at;
            },
            reset() {
                localStorage.removeItem('transfert_data');
            }
        }
    },

    prod: function (prod) {
        const store = this;
        return {

            get add() {
                //check if product already exist
                let old = store.products.find(p => p.id == prod.id);
                if (old) old.qte = (old.qte || 1) + 1;

                else {
                    store.products.unshift({
                        id: prod.id,
                        qte: 1,
                        price: prod.last_purchase_price,
                        discount: prod.discount,
                        original: prod
                    });
                }
                store.storage?.persist();
                this.blink();
            },

            get remove() {
                store.products.splice(store.products.indexOf(prod), 1);
                store.storage?.persist();
                return prod;
            },

            blink() {
                store.blink = prod.id;
                setTimeout(() => store.blink = undefined, 500);
            },

            get txo() {
                return store.taxe_items?.find(t => t.code == prod?.original.taxgroup && t.type == "P");
            },

            get ttc() {
                return prod.price * prod.qte;
            },

            get ht() {
                let txo = this.txo;
                if (!txo?.content) return this.ttc;
                return this.ttc * 100 / (100 + txo.content)
            },

            get taxe() {
                return this.ttc - this.ht;
            },

            get discount() {
                if (prod.discount) return this.ht * prod.discount / 100;
                return 0;
            },

            get real_ht() {
                if (!this.discount) return this.ht;
                return this.ht - this.discount;
            },

            get real_taxe() {
                if (!this.discount) return this.taxe;
                let txo = this.txo;
                if (txo?.content) return this.real_ht * txo.content / 100;
                return 0;
            },

            get real_ttc() {
                if (!this.discount) return this.ttc;
                return this.real_ht + this.real_taxe;
            },
        }
    },

    prods: function () {
        let store = this;
        return {
            get ttc() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).ttc);
                return a;
            },

            get real_ttc() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).real_ttc);
                return a;
            },

            get ht() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).ht);
                return a;
            },

            get real_ht() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).real_ht);
                return a;
            },

            get discount() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).discount);
                return a;
            }
        }
    }

});

Alpine.data('transfert', function(initial = {}) {

    return {
        init() {
            const store = this.$store.transfert;
            store.from_store_id = initial.from_store_id;
            store.from_warehouse_id = initial.from_warehouse_id;
            store.to_store_id = initial.to_store_id;
            store.to_warehouse_id = initial.to_warehouse_id;
            store.register_at = initial.register_at;
            store.wid = initial.wid;

            const old = store.storage?.data();
            if (old.wid != initial.wid) {
                store.storage?.reset();
            } else {
                store.storage?.restore();
            }
        },

        transfert_reset() {
            this.$store.transfert.reset();
            this.$store.transfert.storage.reset();
        },

        transfert_submit() {
            const store = this.$store.transfert;
            this.$wire.call('save_transfert', store.formData());
        }
    }
});
