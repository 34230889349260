Alpine.store('stockout', {

    products: [],
    blink: null,
    register_at: null,
    wid: null,
    motif: null,
    observation: null,
    contact_id: null,
    bl_code: null,
    destinataire: null,

    reset() {
        this.products = [];
        this.blink = null;
        this.register_at = null;
        this.motif = null;
        this.observation = null;
        this.contact_id = null;
        this.bl_code = null;
        this.destinataire = null;
        this.storage?.persist();
        this.received = undefined;
    },

    formData() {

        return {
            products: this.products,
            motif: this.motif,
            observation: this.observation,
            contact_id: this.contact_id,
            bl_code: this.bl_code,
            destinataire: this.destinataire,
            register_at: this.register_at
        };
    },

    isEmpty() {
        return !!(this.products?.length)
    },

    get storage() {
        if (!localStorage) {
            return null;
        }

        let store = this;
        return {
            persist() {
                const data = {
                    products: store.products,
                    observation: store.observation,
                    motif: store.motif,
                    contact_id: store.contact_id,
                    bl_code: store.bl_code,
                    destinataire: store.destinataire,
                    register_at: store.register_at,
                    wid: store.wid
                }
                localStorage.setItem('stockout_data', JSON.stringify(data))
            },
            data() {
                return JSON.parse(localStorage.getItem('stockout_data') || '{}');
            },
            restore(data) {
                if (!data) data = this.data();
                store.products = data.products || [];
                store.motif = data.motif;
                store.observation = data.observation;
                store.contact_id = data.contact_id;
                store.bl_code = data.bl_code;
                store.destinataire = data.destinataire;
                store.register_at = data.register_at;
            },
            reset() {
                localStorage.removeItem('stockout_data');
            }
        }
    },

    prod: function (prod) {
        const store = this;
        return {

            get add() {
                //check if product already exist
                let old = store.products.find(p => p.id == prod.id);
                if (old) old.qte = (old.qte || 1) + 1;

                else {
                    store.products.unshift({
                        id: prod.id,
                        qte: 1,
                        price: prod.last_purchase_price,
                        discount: prod.discount,
                        original: prod
                    });
                }
                store.storage?.persist();
                this.blink();
            },

            get remove() {
                store.products.splice(store.products.indexOf(prod), 1);
                store.storage?.persist();
                return prod;
            },

            blink() {
                store.blink = prod.id;
                setTimeout(() => store.blink = undefined, 500);
            },

            get txo() {
                return store.taxe_items?.find(t => t.code == prod?.original.taxgroup && t.type == "P");
            },

            get ttc() {
                return prod.price * prod.qte;
            },

            get ht() {
                let txo = this.txo;
                if (!txo?.content) return this.ttc;
                return this.ttc * 100 / (100 + txo.content)
            },

            get taxe() {
                return this.ttc - this.ht;
            },

            get discount() {
                if (prod.discount) return this.ht * prod.discount / 100;
                return 0;
            },

            get real_ht() {
                if (!this.discount) return this.ht;
                return this.ht - this.discount;
            },

            get real_taxe() {
                if (!this.discount) return this.taxe;
                let txo = this.txo;
                if (txo?.content) return this.real_ht * txo.content / 100;
                return 0;
            },

            get real_ttc() {
                if (!this.discount) return this.ttc;
                return this.real_ht + this.real_taxe;
            },
        }
    },

    prods: function () {
        let store = this;
        return {
            get ttc() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).ttc);
                return a;
            },

            get real_ttc() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).real_ttc);
                return a;
            },

            get ht() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).ht);
                return a;
            },

            get real_ht() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).real_ht);
                return a;
            },

            get discount() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).discount);
                return a;
            }
        }
    }

});

Alpine.data('stockout', function(initial = {}) {

    return {
        init() {
            const store = this.$store.stockout;
            store.motif = initial.motif;
            store.observation = initial.observation;
            store.contact_id = initial.contact_id;
            store.bl_code = initial.bl_code;
            store.destinataire = initial.destinataire;
            store.register_at = initial.register_at;
            store.wid = initial.wid;

            const old = store.storage?.data();
            if (old.wid != initial.wid) {
                store.storage?.reset();
            } else {
                store.storage?.restore();
            }
        },

        stockout_reset() {
            this.$store.stockout.reset();
            this.$store.stockout.storage.reset();
            $("#pos-s2-contact").val(null).trigger('change');
        },

        stockout_submit() {
            const store = this.$store.stockout;
            this.$wire.call('save_stockout', store.formData());
        }
    }
});
